import { request } from '@/http';



class Model {

  /**
   * 上传证据文件
   */
  uploadEvidence(data) {
    return request('/index/uploadEvidence', {
      method: 'POST',
      data,
    });
  }

  queryCaseDetail(data) {
    return request('/case/detail', {
      method: 'POST',
      data,
    });
  }

  saveEvidence(data) {
    return request('/case/upload', {
      method: 'POST',
      data,
    });
  }

  downCaseEvidence(data) {
    return request('/case/download', {
      method: 'POST',
      data,
      responseType: 'arraybuffer',
      wholeResponse: true,
    });
  }
}

export default new Model();
