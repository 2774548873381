import { forwardRef, useImperativeHandle, useState } from "react";
import Loading from "@/components/loading";

import "./index.scss";

// export default function Skeleton(props: any) {
//   const { children } = props;
//   const [showLoading, setShowLoading] = useState(false);

//   //用useImperativeHandle暴露一些外部ref能访问的属性
//   useImperativeHandle(props.onRef, () => {
//     return {
//       show: show,
//       hide: hide,
//     };
//   });

//   const show = () => {
//     setShowLoading(true);
//   };

//   const hide = () => {
//     setTimeout(() => {
//       setShowLoading(false);
//     }, 500);
//   };

//   return (
//     <div className="skeleton-container">
//       {showLoading && <Loading />}
//       {children}
//     </div>
//   );
// }

const Skeleton = forwardRef((props: any, ref) => {
  const { text, children } = props;
  const [showLoading, setShowLoading] = useState(false);

  //用useImperativeHandle暴露一些外部ref能访问的属性
  useImperativeHandle(ref, () => ({ show, hide }));

  const show = () => {
    setShowLoading(true);
  };

  const hide = () => {
    setShowLoading(false);
  };

  return (
    <div className="skeleton-container">
      {showLoading && <Loading text={text} />}
      {children}
    </div>
  );
});

export default Skeleton;
