import classNames from 'classnames';
import React, { Component } from 'react'
import FunCommonInput, { EnDataType } from '../../../components/common-input';
import FunButton, { EnButtonSize, EnButtonType } from '../../../components/fun-button';
import FunDialog from '../../../components/fun-dialog';
import FunLayout, { EnLayoutType } from '../../../components/fun-layout';
import FunToast from '../../../components/fun-toast';
import Header from '../../../components/header';
import Image from '../../../components/image';
import appointmentSuccessImg from "../../../public/images/icon-appointment-success.png";
import model from '../model';


import './index.scss';

export default class NewAppointment extends Component {

  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const mobile = query.get('mobile');

    this.form = {
      name: '',
      mobile,
      co_name: '', //单位名称
      co_contact: '', //单位联系人名
      co_contact_mobile: '', //联系人电话
      co_address: '', //单位地址
      problem_desc: '', //问题描述
    };

    this.state = {
      show: true,
    };
  }

  componentDidMount() {
    this.setState({ show: true });
  }

  onAppApplication() {
    this.setState({ show: false });
    this.props.history.push('./application-guidelines');
  }

  onContinueApplication() {
    this.setState({ show: false });
  }

  onChangeField(field, value) {
    if (field === 'name') {
      this.form.name = value;
    } else if (field === 'mobile') {
      this.form.mobile = value;
    } else if (field === 'co_name') {
      this.form.co_name = value;
    } else if (field === 'co_contact') {
      this.form.co_contact = value;
    } else if (field === 'co_contact_mobile') {
      this.form.co_contact_mobile = value;
    } else if (field === 'co_address') {
      this.form.co_address = value;
    } else if (field === 'problem_desc') {
      this.form.problem_desc = value;
    }
  }

  onCheckParam() {
    const { name, mobile, co_contact, co_name, co_contact_mobile, co_address, problem_desc } = this.form;
    if (!name) {
      return '请输入本人姓名';
    }
    if (!mobile) {
      return '请输入联系电话';
    }
    if (!co_contact) {
      return '请输入单位名称';
    }
    if (!co_name) {
      return '请输入单位联系人';
    }
    if (!co_contact_mobile) {
      return '请输入联系人电话';
    }
    if (!co_address) {
      return '请输入办公地址';
    }
    if (!problem_desc) {
      return '请输入待解决问题描述';
    }
  }

  onAddAppointment() {
    const error = this.onCheckParam();
    if (error) {
      return FunToast.show(error, 2);
    }
    model.addAppointment(this.form).then(() => {
      FunDialog.show({
        title: '',
        onConfirm: () => {
          this.props.history.replace('/appointment-detail');
        },
        noCancel: true,
        confirmText: '确认',
        toggle: false,
        children: <div className="new-appointment-success-container">
          <div className="new-appointment-success-img">
            <Image scale={265 / 198} src={appointmentSuccessImg} />
          </div>
          <div className="new-appointment-success-text">预约成功</div>
          <span className={classNames("new-appointment-success-notice", "new-appointment-success-notice-margin")} >申请成功，48 小时内会来电确认，</span>
          <span className="new-appointment-success-notice">请注意接听。</span>
        </div>
      });
    });
  }

  render() {
    const { show } = this.state;
    return (
      <div className="new-appointment-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header className="top" hidden={false} hasBack history={this.props.history}>
            新建预约
          </Header>
          <div className="new-appointment-content-container">

            <div className="new-appointment-sub-title">基础信息</div>
            <FunCommonInput
              className="new-appointment-item-container"
              dataType={EnDataType.none}
              placeholder={'本人姓名'}
              leftIcon={'icon-user'}
              maxLength={20}
              onChange={(value) => this.onChangeField('name', value)}
            />
            <FunCommonInput
              className="new-appointment-item-container"
              dataType={EnDataType.none}
              placeholder={'联系电话'}
              leftIcon={'icon-mobile'}
              type={'number'}
              maxLength={11}
              defaultValue={this.form.mobile}
              onChange={(value) => this.onChangeField('mobile', value)}
            />
            <FunCommonInput
              className="new-appointment-item-container"
              dataType={EnDataType.none}
              placeholder={'单位名称'}
              leftIcon={'icon-home-no-fill'}
              maxLength={20}
              onChange={(value) => this.onChangeField('co_name', value)}

            />
            <FunCommonInput
              className="new-appointment-item-container"
              dataType={EnDataType.none}
              placeholder={'单位联系人'}
              leftIcon={'icon-user'}
              maxLength={20}
              onChange={(value) => this.onChangeField('co_contact', value)}
            />
            <FunCommonInput
              className="new-appointment-item-container"
              dataType={EnDataType.none}
              placeholder={'联系人电话'}
              leftIcon={'icon-mobile'}
              maxLength={20}
              onChange={(value) => this.onChangeField('co_contact_mobile', value)}

            />
            <FunCommonInput
              className="new-appointment-item1"
              dataType={EnDataType.none}
              placeholder={'办公地址'}
              leftIcon={'icon-location-no-fill'}
              multiLine
              rows={3}
              maxLength={20}
              onChange={(value) => this.onChangeField('co_address', value)}
            />

            <div className={classNames("new-appointment-sub-title", "new-appointment-question-detail")}>问题详情</div>
            <FunCommonInput
              className="new-appointment-item1"
              dataType={EnDataType.none}
              placeholder={'待解决问题描述（限制20个字以内）'}
              leftIcon={'icon-notification'}
              multiLine
              rows={5}
              maxLength={20}
              onChange={(value) => this.onChangeField('problem_desc', value)}
            />

            <FunButton
              className="new-appointment-btn"
              type={EnButtonType.primary}
              size={EnButtonSize.big}
              onClick={this.onAddAppointment.bind(this)}
            >
              提 交
            </FunButton>
          </div>
        </FunLayout>
        <FunDialog
          title=''
          isShow={show}
          customShow
          noTitle
          noCancel
          noConfirm
          toggle={false}
          children={
            <div className="new-appointment-attention-container">
              <span className="new-appointment-attention-title">我要APP申请</span>
              <span className='new-appointment-attention'>此功能用于引导在通过智能手机申请劳动人事争议仲裁；如您对申请事项有所了解可以尝试直接在<span style={{ fontWeight: 'bold' }}>上海人社APP</span>上提出申请。</span>
              <FunButton
                className="new-appointment-attention-app-btn"
                type={EnButtonType.primary}
                size={EnButtonSize.big}
                onClick={this.onAppApplication.bind(this)}
              >
                我要APP申请
              </FunButton>
              <div className="new-appointment-attention-line" />
              <span className="new-appointment-attention-title">我要继续预约</span>
              <span className='new-appointment-attention'>如您尝试后无法顺利申请或有其他疑问的可以按下一步预约电话咨询。预约成功后我们将在<span style={{ fontWeight: 'bold' }}>48小时内</span>与您沟通，帮助您完成仲裁申请。</span>
              <FunButton
                className="new-appointment-attention-btn"
                type={EnButtonType.primary}
                size={EnButtonSize.big}
                onClick={this.onContinueApplication.bind(this)}
              >
                我要继续预约
              </FunButton>
            </div>
          }
        />
      </div >
    );
  }
}