import React, { useEffect, useState } from "react"
import './index.scss';
import model from "./model";
import Image from "@/components/image";
import Utils from "@/common/utils";
import FunLayout, { EnLayoutType } from "@/components/fun-layout";
import Header from "@/components/header";

export default function MessageContent(props: any) {
  const [urlTitle, setUrlTitle] = useState('');
  const [urlTemplate, setUrlTemplate] = useState('');
  const [imgs, setImgs] = useState([]);

  const query = new URLSearchParams(props.location.search);
  const idx = query.get("idx") || '';

  const onQueryCaseDetail = (idx: string) => {
    model
      .queryMessageDetail(idx)
      .then((res: any) => {
        const { noticeTemplate: { url_template_full, url_title }, template_param } = res;
        setUrlTitle(url_title);
        setUrlTemplate(url_template_full);
        const templateParam = JSON.parse(template_param);
        const { img = [] } = templateParam;
        setImgs(img);
      });
  }

  useEffect(() => {
    onQueryCaseDetail(idx);
  }, [idx]);
  return (
    <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
      <Header className="top" hidden={false}  >
        消息通知
      </Header>
      <div className="message-content-container">
        <div className="message-title">{urlTitle}</div>
        <div
          className="message-content"
          dangerouslySetInnerHTML={{
            __html: Utils.formatRichText(urlTemplate),
          }}
        />
        {imgs.map((item, index) => <Image key={index} src={Utils.getFullImg(item)} />)}
      </div>
    </FunLayout>

  );
}