export const getTypeByName = (name: string): string => {
  if (name.includes("bmp") || name.includes("jpg") || name.includes("png")) {
    return "image/bmp";
  } else if (name.includes("jpg")) {
    return "image/jpg";
  } else if (name.includes("png")) {
    return "image/png";
  } else if (name.includes("pdf")) {
    return "application/pdf";
  } else if (name.includes("doc")) {
    return "application/msword";
  } else if (name.includes("docx")) {
    return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  } else if (name.includes("mp3")) {
    return "audio/mp3";
  } else if (name.includes("mp4")) {
    return "video/mp4";
  } else if (name.includes("mov")) {
    return "video/mov";
  } else if (name.includes("txt")) {
    return "text/plain";
  } else if (name.includes("csv")) {
    return "text/plain";
  } else if (name.includes("application/vnd.ms-excel")) {
    return "text/plain";
  } else if (name.includes("xsx")) {
    return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  }
  return "";
};

export const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return "0 Bytes";

  const units = ["Bytes", "KB", "MB", "GB", "TB"];
  const base = 1024;
  const digitGroups = Math.floor(Math.log(bytes) / Math.log(base));

  return `${(bytes / Math.pow(base, digitGroups)).toFixed(2)} ${
    units[digitGroups]
  }`;
};
