import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import StableJsonStringify from 'fast-json-stable-stringify';
import { handleHttpError, handleBusinessError } from './errorHandler';
import { hashCode } from './md5';
import { messager, setMessager } from './defaultMessenger';
import './interceptors';

/**
 * 请求属性
 */
export interface RequestOptions extends AxiosRequestConfig {
  /**
   * 初始值
   */
  initialData?: T;
  /**
   * 声明需要自己处理的错误码
   */
  handleError?: string | string[];
  /**
   * 成功提示消息，不为空时弹出成功提示
   */
  successMsg?: string;
}

/**
 * 请求返回值结构
 */
export interface RequestResponse {
  /**
   * 状态
   */
  success: boolean;
  /**
   * 状态码，200 为成功
   */
  code: number;
  /**
   * 状态消息
   */
  message: string;
  /**
   * 返回数据
   */
  data?: T;
  /**
   * axios 返回
   */
  response?: AxiosResponse;
}

// 存放正在发起的请求，用于防重
const requestMap = new Map();

/**
 * HTTP请求工具类
 */
export class HttpClient {
  /**
   * 通用方法请求调用
   * **注意：post 请求，参数要放在 data 中，get 请求，参数要放在 params 中**
   * @param {Object} options 请求配置
   */
  static request(options) {
    const { initialData, handleError, successMsg, ...config } = options;

    const requestHash = hashCode(StableJsonStringify({
      url: options.url,
      method: options.method,
      params: options.params,
      data: options.data
    }))
    const requesting = requestMap.get(requestHash);

    // 相同的请求还未结束，直接返回上次的请求（无需重复发送请求）
    if (requesting) {
      return requesting;
    }

    config.headers = { 'X-Requested-With': 'XMLHttpRequest', ...config.headers };

    const newRequest = new Promise((resolve, reject) => {
      axios({
        timeout: 60000,
        ...config,
      })
        .then(res => {
          const { status, statusText } = res;
          const { success, code, message, data = initialData, ...restData } = res?.data || {};
          const response = {
            success,
            code: +code,
            message,
            data,
            ...restData,
            response: res,
          };
          if (status === 200) {
            let wholeResponse = false;
            if (config) {
              wholeResponse = config.wholeResponse;
            }
            // 业务成功处理流程
            if (code === 200) {
              // 自动处理成功消息提示
              if (typeof (successMsg) === 'string' && successMsg !== '') {
                messager && messager.success(successMsg);
              }

              resolve(wholeResponse ? res : data);
            } else if (code === undefined && statusText === 'OK') {
              resolve(wholeResponse ? res : data);
            }
            // 业务异常处理流程
            else {
              handleBusinessError(response, handleError, resolve, reject);
            }
          } else {
            handleBusinessError(response, handleError, resolve, reject);
          }
        })
        // Http异常处理流程
        .catch(err => {
          handleHttpError(err);

          // 转换格式
          const { success = false, code = -1, message = 'request err', data = initialData } = err?.data || {};
          const response = {
            success,
            code: +code,
            message,
            data,
            response: err,
          };
          reject(response);
        }).finally(() => {
          // 请求完成后，删除 map 记录
          requestMap.delete(requestHash);
        });
    });

    // 存储新 request
    requestMap.set(requestHash, newRequest);

    return newRequest;
  }


  /**
   * GET方式请求调用
   * HttpClient.get(url[, param[, config]])
   *
   * @param {string} url 请求地址
   * @param {Object} params 请求参数
   * @param {Object} config 请求配置
   */
  static get(url, params, config) {
    let options = {};

    if (config) {
      options = { ...config, ...{ params, url } };
    } else if (params) {
      options = { url, params };
    } else if (typeof url === 'string') {
      options = { url };
    } else {
      options = url;
    }

    options.method = 'get';

    return this.request(options);
  }

  /**
   * POST 方式请求调用
   * HttpClient.post(url[, data[, config]])
   *
   * @param {string} url 请求地址
   * @param {?Object} data 请求参数
   * @param {?Object} config 请求配置
   */
  static post(url, data, config) {
    let options = {};

    if (config) {
      options = { ...config, ...{ data, url } };
    } else if (data) {
      options = { url, data };
    } else if (typeof url === 'string') {
      options = { url };
    } else {
      options = url;
    }

    options.method = 'post';

    return this.request(options);
  }

  /**
   * 暴露axios对象，给调用方重置它的属性的机会
   */
  static axios = axios;

  /**
   * 设置错误提示器
   */
  static setMessager = setMessager;
}

/**
 * 默认 http 请求，method 默认 post
 * **注意：post 请求，参数要放在 data 中，get 请求，参数要放在 params 中**
 * @param url 
 * @param options 
 */
export function request(url, options) {
  const config = { method: 'POST', ...options, url };

  return HttpClient.request(config);
}
