import { Component } from "react";
import FunButton, { EnButtonSize, EnButtonType } from "../../../components/fun-button";
import FunDialog from "../../../components/fun-dialog";
import FunLayout, { EnLayoutType } from "../../../components/fun-layout";
import FunToast from "../../../components/fun-toast";
import Header from "../../../components/header";
import Image from "../../../components/image";
import mineAppointmentImg from "../../../public/images/icon-mine-appointment.png";
import model from "../model";

import './index.scss';

// 预约状态  1-新建 2-已回电 3-已确认 4-已结案 9-已取消
const AppointmentStatusText = {
  1: '新建',
  2: '已回电',
  3: '已确认',
  4: '已结案',
  5: '已取消',
}

const AppointmentStatus = {
  'newAdd': 1,
  'called': 2,
  'confirmed': 3,
  'closed': 4,
  'canceled': 5,
}

export default class AppointmentDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      info: {},
      basicInfos: [],
    };
  }

  componentDidMount() {
    this.onGetAppointmentDetail();
  }

  onGetAppointmentDetail() {
    model.queryAppointmentDetail().then(res => {
      const {
        appointmentTimeStr,
        apply_name,
        apply_mobile,
        apply_co_name,
        apply_co_contact,
        apply_co_contact_mobile,
        apply_co_addr,
      } = res;
      const { basicInfos = [] } = this.state;
      basicInfos.splice(0, basicInfos.length);
      appointmentTimeStr && basicInfos.push({ id: 1, key: '预约时间:', value: appointmentTimeStr, color: '#f15d61', fontWeight: 'bolder' });
      basicInfos.push({ id: 2, key: '本人姓名:', value: apply_name });
      basicInfos.push({ id: 3, key: '联系电话:', value: apply_mobile });
      basicInfos.push({ id: 4, key: '单位名称:', value: apply_co_name });
      basicInfos.push({ id: 5, key: '单位联系人:', value: apply_co_contact });
      basicInfos.push({ id: 6, key: '联系人电话:', value: apply_co_contact_mobile });
      basicInfos.push({ id: 7, key: '办公地址:', value: apply_co_addr });
      this.setState({ basicInfos, info: res });
    });
  }

  onGenerateButtonText(status) {
    let btnText;
    switch (status) {
      case AppointmentStatus.newAdd:
      case AppointmentStatus.confirmed:
        btnText = '取 消';
        break;
      case AppointmentStatus.called:
        btnText = '确 认';
        break;
      default:
        btnText = ''
    }
    return btnText;
  }

  onConfirmAppointment() {
    const { info: { id } } = this.state;
    FunDialog.show({
      title: '您要确认预约时间吗？',
      onConfirm: () => {
        model.confirmAppointment({ id }).then(() => {
          FunToast.show('确认成功');
          this.onGetAppointmentDetail();
        });
      },
      confirmText: '确定',
    });
  }

  onCancelAppointment() {
    const { info: { id } } = this.state;
    FunDialog.show({
      title: '您要取消预约吗？',
      onConfirm: () => {
        model.cancelAppointment({ id }).then(() => {
          FunToast.show('取消成功');
          this.props.history.replace('/new-appointment');
        });
      },
      confirmText: '确定',
    });
  }

  render() {
    const { basicInfos, info = {} } = this.state;
    const { prob_desc = '', status } = info;
    return (
      <div className="appointment-detail-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header
            className="top"
            hidden={false}
            hasBack={false}
            isNoBorder
          >
            我的预约
          </Header>
          <div className="appointment-detail-top-cotainer">
            <div className="appointment-detail-top-bg" />
            <div className="appointment-detail-top-content-container">
              <div className="appointment-detail-status-img">
                <Image scale={1} src={mineAppointmentImg} />
              </div>
              <div className="appointment-detail-work-order-status">{`工单状态：${AppointmentStatusText[status]}`}</div>
              <div className="appointment-detail-attention">
                请按信息提示在指定时间段到现场并务必携带<span style={{ color: '#1C9145', fontWeight: 'bolder' }}>48小时内核酸阴性报告</span>、<span style={{ color: '#CBB486', fontWeight: 'bolder' }}>身份证</span>，确认健康码为绿码，到现场后请按照保安人员要求完成现场防疫登记。
              </div>
            </div>
          </div>

          <div className="appointment-detail-content-container">
            <div className="appointment-detail-sub-title">基础信息</div>

            <div className="appointment-detail-basic-info-item-container">
              {basicInfos.map(item => {
                return (
                  <div className="appointment-detail-basic-info-item" key={item.id}>
                    <div className="appointment-detail-basic-info-item-left" style={{ color: item.color, fontWeight: item.fontWeight }}>{item.key}</div>
                    <div className="appointment-detail-basic-info-item-right" style={{ color: item.color, fontWeight: item.fontWeight }}>{item.value}</div>
                  </div>
                );
              })}
            </div>

            <div className="appointment-detail-sub-title">问题详情</div>
            <div className="appointment-detail-question-detail">{prob_desc}</div>

            {
              <div>
                {AppointmentStatus.called === status && <FunButton
                  type={EnButtonType.primary}
                  size={EnButtonSize.big}
                  onClick={this.onConfirmAppointment.bind(this)}
                >
                  确认预约时间
                </FunButton>}
                {(AppointmentStatus.newAdd === status ||
                  AppointmentStatus.confirmed === status ||
                  AppointmentStatus.called === status) && <FunButton
                    className="appointment-detail-btn"
                    type={EnButtonType.primary}
                    size={EnButtonSize.big}
                    onClick={this.onCancelAppointment.bind(this)}
                  >
                    我要取消预约
                  </FunButton>}
              </div>
            }
          </div>


        </FunLayout >
      </div >
    );
  }
}