import { request } from '../../http';

class Model {

  /**
   * 用户新建预约工单
   */
  addAppointment(data) {
    return request('/user/appointment/add', {
      method: 'POST',
      data,
    });
  }

  /**
  * 
  * 用户取消预约工单
  */
  cancelAppointment(data) {
    return request('/user/appointment/cancel', {
      method: 'POST',
      data,
    });
  }

  /**
   * 
   * 用户确认预约工单
   */
  confirmAppointment(data) {
    return request('/user/appointment/confirm', {
      method: 'POST',
      data,
    });
  }

  /**
   * 获取预约工单详情
   */
  queryAppointmentDetail(data) {
    return request('/user/appointment/exist', {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
