import FunIcon from "@/components/fun-icon";
import Image from "@/components/image";
import docImg from "@/public/images/icon-doc.png";
import imageImg from "@/public/images/icon-img.png";
import pdfImg from "@/public/images/icon-pdf.png";
import videoImg from "@/public/images/icon-video.png";
import audioImg from "@/public/images/icon-audio.png";
import { FileItem } from "./data";

export default function FileListItem(props: {
  fileListTitle: string;
  files: FileItem[];
  onDel: Function;
}) {
  const { fileListTitle, files = [], onDel } = props;

  const getFileLabelImg = (type: string) => {
    console.log("getFileLabelImg:", type);
    if (type.startsWith("image")) {
      return imageImg;
    } else if (
      type === "application/msword" ||
      type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return docImg;
    } else if (type === "application/pdf") {
      return pdfImg;
    } else if (type.startsWith("audio")) {
      return audioImg;
    } else if (type.startsWith("video")) {
      return videoImg;
    } else if (type === "text/plain") {
      return docImg;
    } else if (
      type === ".csv" ||
      type === "application/vnd.ms-excel" ||
      type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return docImg;
    }
  };

  const onDelete = (item: FileItem) => {
    onDel && onDel(item);
  };

  return (
    <div className="uploaded-material">
      <span className="sub-title">{fileListTitle}</span>
      {files.map((item: FileItem, index: number) => {
        return (
          <div className="material-item" key={index}>
            <div className="material-name-container">
              <Image src={getFileLabelImg(item.type)} />
              <span className="material-name">{item.name}</span>
            </div>
            <FunIcon icon={"icon-close"} onClick={() => onDelete(item)} />
          </div>
        );
      })}
    </div>
  );
}
