import { Component } from "react";
import FunLayout, { EnLayoutType } from "../../../components/fun-layout";
import Header from "../../../components/header";
import Image from "../../../components/image";
import rensheAndroidImg from "../../../public/images/icon-shanghai-renshe-android.png";
import rensheIOSImg from "../../../public/images/icon-shanghai-renshe-ios.png";
import stepTwoImg from "../../../public/images/icon-step-two.png";
import stepTwo1Img from "../../../public/images/icon-step-two1.png";
import stepThreeImg from "../../../public/images/icon-step-three.png";
import stepFourImg from "../../../public/images/icon-step-four.png";
import stepFiveImg from "../../../public/images/icon-step-five.png";
import stepFive1Img from "../../../public/images/icon-step-five1.png";
import stepSixImg from "../../../public/images/icon-step-six.png";

import './index.scss';

export default class ApplicationGuidelines extends Component {
  render() {
    return (

      <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
        <Header className="top" hidden={false} hasBack isNoBorder history={this.props.history} >
          网上调解申请指南
        </Header>
        <div className="application-guidelines-container">
          <span className="application-guidelines-title">网上调解申请指南</span>
          <span className="application-guidelines-sub-title">步骤(1/7)</span>
          <span className="application-guidelines-step-content">下载安装“上海人社〞APP，应用商店搜素〝上海人社〞，或扫描以下二维码下载。</span>
          <div className="application-guidelines-step-one-img-container">
            <div className="application-guidelines-step-one-img">
              <Image scale={231 / 288} src={rensheAndroidImg} />
            </div>
            <div className="application-guidelines-step-one-img">
              <Image scale={231 / 288} src={rensheIOSImg} />
            </div>
          </div>

          <span className="application-guidelines-sub-title">步骤(2/7)</span>
          <span className="application-guidelines-step-content">点击进入“调解仲裁〞。</span>
          <div className="application-guidelines-step-two-img">
            <Image scale={642 / 1325} src={stepTwoImg} />
          </div>
          <div className="application-guidelines-step-two1-img">
            <Image scale={642 / 373} src={stepTwo1Img} />
          </div>

          <span className="application-guidelines-sub-title">步骤(3/7)</span>
          <span className="application-guidelines-step-content">选择“调解申请〞。</span>
          <div className="application-guidelines-step-three-img">
            <Image scale={642 / 576} src={stepThreeImg} />
          </div>

          <span className="application-guidelines-sub-title">步骤(4/7)</span>
          <span className="application-guidelines-step-content">阅读“告知〞。</span>
          <div className="application-guidelines-step-four-img">
            <Image scale={642 / 1325} src={stepFourImg} />
          </div>

          <span className="application-guidelines-sub-title">步骤(5/7)</span>
          <span className="application-guidelines-step-content">准确填写个人及单位信息。</span>
          <div className="application-guidelines-step-five-img">
            <Image scale={642 / 1012} src={stepFiveImg} />
          </div>
          <div className="application-guidelines-step-five-img">
            <Image scale={642 / 1004} src={stepFive1Img} />
          </div>

          <span className="application-guidelines-sub-title">步骤(6/7)</span>
          <span className="application-guidelines-step-content">准确填写请求事项。</span>
          <div className="application-guidelines-step-six-img">
            <Image scale={642 / 1022} src={stepSixImg} />
          </div>

          <span className="application-guidelines-sub-title">步骤(7/7)</span>
          <span className="application-guidelines-step-content">网上申请提交后，会有工作人员通过电话等形式联系双方当事人组织调解，请保持手机畅通。</span>
          <div className="application-guidelines-step-seven-attention-container">
            <span className="application-guidelines-step-seven-attention">注：1、所有信息必须保证真实有效，个人姓名地址必须是可送达的详细地址，需要完整准备写、格式为***区**路***弄***号***室</span>
            <span className="application-guidelines-step-seven-attention">2、可通过工商官网查询单位注册登记信息、请注意输入准确的单位全称。工商官网：http://www.gsxt.gov.cn/index.html需确保单位信息准确有效。</span>
            <span className="application-guidelines-step-seven-attention">3、如被退回，请先查阅退回原因，并有相关人员主动致电，告知修改方式；</span>
            <span className="application-guidelines-step-seven-attention">4、如被接收，客服人员会在3个工作日内电话联系申请人，申请人需提供关于劳动合同的详细信息；</span>
            <span className="application-guidelines-step-seven-attention">5、客服人员录入后.会返回到调解组织、请耐心等待调解组织安排时间进行调解：</span>
            <span className="application-guidelines-step-seven-attention">6、以上相关人员外呼所用的电话号码不同，请不要设置呼叫拦截，以免无法接听来电。</span>
          </div>
        </div>

      </FunLayout>
    );
  }
}