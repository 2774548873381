import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import FileUpload from "@/components/file-upload";
import FunButton, { EnButtonType } from "@/components/fun-button";
import FunIcon from "@/components/fun-icon";
import FunLayout, { EnLayoutType } from "@/components/fun-layout";
import Header from "@/components/header";
import FunToast from "@/components/fun-toast";
import Platform from "@/common/platform";
import FileListItem from "../FileListItem";
import { CaseDetail, CaseEvidencesItem, FileItem } from "../data";
import { getTypeByName, formatFileSize } from "../case-utils";
import model from "../model";

import "./index.scss";
import Skeleton from "../skeleton";
import Utils from "@/common/utils";
import FunDialog from "@/components/fun-dialog";

export default function DownloadMaterial(props: any) {
  const [files, setFiles] = useState<FileItem[]>([]);
  const [caseDetail, setCaseDetail] = useState<CaseDetail>();

  const query = new URLSearchParams(props.location.search);
  const idx = query.get("idx");
  const mobile = query.get("mobile");
  const identity = query.get("identity");
  const isApplyer = 0;

  const skeletonRef = useRef();

  useEffect(() => {
    model
      .queryCaseDetail({
        idx, // 案件对外展示编号,从链接中获取
        isApplyer, // 是否是申请人，1-是 ，0-否
        mobile, // 登陆成功的手机号
        identity,
      })
      .then((res: CaseDetail) => {
        setCaseDetail(res || {});
        if (res && res.evidences) {
          const _files = [] as FileItem[];
          res.evidences.map((item: CaseEvidencesItem) => {
            const { file_url, name, size } = item;
            return _files.push({
              url: file_url,
              name,
              type: getTypeByName(name),
              size,
            });
          });
          setFiles(_files);
        }
      });

    return () => {
      console.log("download material unmount");
      // Utils.setLogin(false);
    };
  }, [idx]);

  const getTotalSize = (): string => {
    let totalSize = 0;
    files.map((item) => {
      const { size } = item;
      return (totalSize += parseFloat(size));
    });
    return formatFileSize(totalSize);
  };

  const onDownload = () => {
    if (Platform.isWechat) {
      FunToast.show("请用浏览器打开下载哦～");
      return;
    }
    skeletonRef?.current?.show();
    const param = { idx, mobile, identity };
    model
      .downCaseEvidence(param)
      .then((res: any) => {
        console.log("downCaseEvidence:", res);
        if (res.status === 200) {
          const newBlob = new Blob([res.data], { type: "application/zip" });
          const blobUrl = window.URL.createObjectURL(newBlob);
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.href = blobUrl;
          a.download = "证据材料";
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(blobUrl);
          }, 250);
          FunDialog.show({
            title: "下载成功",
            onConfirm: () => {
              Utils.setLogin(false);
              props.history.goBack();
            },
            noCancel: true,
            confirmText: "确定",
          });
        } else {
          FunToast.show("下载失败");
        }
      })
      .finally(() => {
        skeletonRef?.current?.hide();
      });
  };

  return (
    <FunLayout
      type={EnLayoutType.fixedLayout}
      className={"main-content-p"}
      isAdaptorKeyboard={true}
    >
      <Header hidden={false} className={"top"} history={props.history}>
        长宁劳动仲裁服务平台
      </Header>

      <Skeleton ref={skeletonRef} text={"下载中"}>
        <div className="download-material-container">
          <span className="main-title">案件信息和证据材料</span>

          <div className="case-name-container">
            <span className="sub-title">案件名：</span>
            <span className="content">
              [{caseDetail?.number}] [{caseDetail?.applyer_name}]诉[
              {caseDetail?.respondent_name}]
            </span>
          </div>

          <div className="case-material">
            <span className="sub-title">证据材料：</span>
            <FileUpload
              fileList={files}
              selectable={false}
              accept="
          image/bmp,image/jpg,image/png,
          text/plain,
          application/msword,
          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
          application/pdf,
          audio/mp3,
          video/mp4,
          video/mov,
          .csv,
          application/vnd.ms-excel,
          application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
          "
              FileListItem={FileListItem}
              fileListItemClassName="mine-avatar-upload-img"
              uploadComponent={
                <FunButton
                  className={classNames("button-container", "button-width")}
                  type={EnButtonType.white}
                >
                  <div className="button-inner ">
                    <FunIcon icon={"icon-upload"} />
                    <span>上传证据材料</span>
                  </div>
                </FunButton>
              }
            />
            <span className="material-total-des">
              证据材料总计大小:
              <span style={{ fontWeight: "bold" }}>{getTotalSize()}</span>
              ，为保证完整下载请在电脑浏览器下载材料或转存到云盘。证据材料较大，为节省公共资源请勿多次下载
            </span>

            <FunButton className="button-container" type={EnButtonType.primary}>
              <div className="button-inner" onClick={onDownload}>
                <FunIcon icon={"icon-upload"} />
                <span>证据材料打包下载</span>
              </div>
            </FunButton>
          </div>
        </div>
      </Skeleton>
    </FunLayout>
  );
}
