import React, { Fragment } from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import Login from './pages/login';
import LoginOtp from './pages/login-otp/index';
import ForgetPwd from './pages/forget-pwd';
import Register from './pages/register';
import History from './common/url/history';
import NewAppointment from './pages/appointment/new-appointment';
import AppointmentDetail from './pages/appointment/appointment-detail';
import ApplicationGuidelines from './pages/appointment/application-guidelines';
import UploadMaterial from './pages/case-manage/upload-material/index';
import DownloadMaterial from './pages/case-manage/download-material/index';
import MessageContent from './pages/case-manage/message-content/index';

import './public/font/style.css';
import './public/eshimin.js';
import './App.scss';

function App() {
  return (
    <Fragment>
      <HashRouter history={History}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/login-otp" component={LoginOtp} />
          <Route path="/forget-pwd" component={ForgetPwd} />
          <Route path="/register" component={Register} />
          <Route path="/new-appointment" component={NewAppointment} />
          <Route path="/appointment-detail" component={AppointmentDetail} />
          <Route path="/application-guidelines" component={ApplicationGuidelines} />
          <Route path="/upload-material" component={UploadMaterial} />
          <Route path="/download-material" component={DownloadMaterial} />
          <Route path="/message-content" component={MessageContent} />
          <Redirect to={"/login"} />
        </Switch>
      </HashRouter>
    </Fragment>
  );
}

export default App;
