import { request } from '../../../http';
class Model {

  // 案件详情
  queryMessageDetail(idStr) {
    return request(`/notice_msg/detail_by_id_str?idStr=${idStr}`, {
      method: 'GET',
    });
  }
}

export default new Model();
