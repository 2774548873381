import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FunIcon from '../fun-icon';
import Utils from '../../common/utils';
import classNames from 'classnames';
import './index.scss';
/**
 * 文件上传
 * 目前只支持上传 1 个
 */
export default class FileUpload extends Component {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    fileList: PropTypes.any, // 文件数组
    accept: PropTypes.string,
    multiple: PropTypes.bool,
    inputProps: PropTypes.any,
    labelProps: PropTypes.any,
    buttonProps: PropTypes.any,
    onChange: PropTypes.func,
    selectable: PropTypes.bool,
    error: PropTypes.bool,
    fileListTitle: PropTypes.string,
    FileListItem: PropTypes.any, // 选择文件后对应的展示组件
  };
  constructor(props) {
    super(props);
    const { id } = props;
    this.inputId = id ? id : Utils.v4();
  }

  fileChange() {
    const { onChange } = this.props;
    const input = document.getElementById(this.inputId);
    console.log('files:', input?.files);
    onChange && onChange(input?.files);
  };

  onDel(file) {
    const { onRemove } = this.props;
    onRemove && onRemove(file);
  }

  render() {
    const {
      className,
      accept = 'image/*',
      multiple = false,
      inputProps,
      selectable,
      FileListItem,
      uploadComponentClassName,
      uploadComponent,
      fileListTitle,
      fileList,
    } = this.props;
    return (
      <div className={classNames("file-upload-container", className)}>

        {selectable ? (
          <div className={classNames("file-list-upload", uploadComponent ? uploadComponentClassName : 'file-list-upload-default ')}>
            {uploadComponent ? <div>{uploadComponent}</div> : <FunIcon icon='icon-add' />}
            <input
              id={this.inputId}
              accept={accept}
              multiple={multiple}
              type="file"
              onChange={this.fileChange.bind(this)}
              {...inputProps}
            />
          </div>
        ) : null}

        {FileListItem && <div>{<FileListItem fileListTitle={fileListTitle} files={fileList} onDel={(file) => this.onDel(file)} />}</div>}
      </div>
    );
  }
}
