import { useEffect, useRef, useState } from "react";
import FunLayout, { EnLayoutType } from "@/components/fun-layout";
import Header from "@/components/header";
import FunButton, { EnButtonSize, EnButtonType } from "@/components/fun-button";
import FunIcon from "@/components/fun-icon";
import FileUpload from "@/components/file-upload";
import FunToast from "@/components/fun-toast";
import FunDialog from "@/components/fun-dialog";
import FileListItem from "../FileListItem";
import Skeleton from "../skeleton";
import Utils from "@/common/utils";
import { CaseDetail, CaseEvidencesItem, FileItem } from "../data";
import model from "../model";

import "./index.scss";

export default function UploadMaterial(props: any) {
  const [files, setFiles] = useState<FileItem[]>([]);
  const [caseDetail, setCaseDetail] = useState<CaseDetail>();

  const query = new URLSearchParams(props.location.search);
  const idx = query.get("idx");
  const mobile = query.get("mobile");
  const identity = query.get("identity");

  const maxSize = 1024 * 1024 * 30;
  const isApplyer = 1;

  const skeletonRef = useRef();

  const showTip = () => {
    FunDialog.show({
      title: "",
      onConfirm: () => {},
      noCancel: true,
      confirmText: "确定",
      toggle: false,
      children: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "justify",
            padding: "0 20px",
          }}
        >
          <p>
            1、本平台系仲裁庭审证据交换程序辅助平台，请当事人将证据材料的扫描件或照片件上传至本平台，提交的电子证据应与实体证据一致，如需撤回所提交的证据请与案件承办仲裁员联系
          </p>
          <p>2、开庭时请携带证据材料原件及一式两份的复印件</p>
          <p>
            3、如证据中包含音频视频证据请使用电脑端上传并附上对应文字材料，实体证据需将音频视频刻录光盘提交
          </p>
        </div>
      ),
    });
  };

  useEffect(() => {
    model
      .queryCaseDetail({
        idx, // 案件对外展示编号,从链接中获取
        isApplyer, // 是否是申请人，1-是 ，0-否
        mobile, // 登陆成功的手机号
        identity,
      })
      .then((res: CaseDetail) => {
        showTip();
        setCaseDetail(res || {});
        if (res && res.evidences) {
          const _files = [] as FileItem[];
          res.evidences.map((item: CaseEvidencesItem) => {
            const { file_url, name, size = 0 } = item;
            return _files.push({
              url: Utils.getSubImg(file_url),
              name,
              type: getTypeByName(name),
              size,
            });
          });
          setFiles(_files);
        }
      });
    return () => {
      console.log("upload material unmount");
      // Utils.setLogin(false);
    };
  }, [idx]);

  const getTypeByName = (name: string): string => {
    if (name.includes("bmp") || name.includes("jpg") || name.includes("png")) {
      return "image/bmp";
    } else if (name.includes("jpg")) {
      return "image/jpg";
    } else if (name.includes("png")) {
      return "image/png";
    } else if (name.includes("pdf")) {
      return "application/pdf";
    } else if (name.includes("doc")) {
      return "application/msword";
    } else if (name.includes("docx")) {
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    } else if (name.includes("mp3")) {
      return "audio/mp3";
    } else if (name.includes("mp4")) {
      return "video/mp4";
    } else if (name.includes("mov")) {
      return "video/mov";
    } else if (name.includes("txt")) {
      return "text/plain";
    } else if (name.includes("csv")) {
      return "text/plain";
    } else if (name.includes("application/vnd.ms-excel")) {
      return "text/plain";
    } else if (name.includes("xsx")) {
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }
    return "";
  };

  const onUpload = (_files: any) => {
    const file = _files[0];
    const { name, type } = file || {};

    if (file?.size > maxSize) {
      FunToast.show("文件大小不能超过30M");
      return;
    }
    const formData = new FormData();
    formData.append("evidence", file);
    skeletonRef?.current?.show();
    model
      .uploadEvidence(formData)
      .then((res: { scr: string; size: string }) => {
        const { scr, size } = res;
        const list = [...files];
        list.push({ url: scr, name, type, size });
        console.log("list:", list);
        setFiles(list);
      })
      .finally(() => {
        skeletonRef?.current?.hide();
      });
  };

  const onRemove = (file: FileItem) => {
    files.forEach((item: FileItem, index: number) => {
      if (
        item.name === file.name &&
        item.url === file.url &&
        item.type === file.type
      ) {
        files.splice(index, 1);
        return;
      }
    });
    setFiles([...files]);
  };

  const onFinishUpload = () => {
    if (files.length === 0) {
      FunToast.show("请上传证据材料");
      return;
    }
    const { idx } = caseDetail || {};
    const params = {
      idx,
      evidenceList: [...files],
      mobile,
      identity,
    };
    skeletonRef?.current?.show();
    model
      .saveEvidence(params)
      .then((res: { code: number }) => {
        FunDialog.show({
          title: "上传成功，确定后退出系统",
          onConfirm: () => {
            Utils.setLogin(false);
            props.history.push(`/login-otp?idx=${idx}&isApplyer=${isApplyer}`);
          },
          noCancel: true,
          confirmText: "确定",
        });
      })
      .finally(() => {
        skeletonRef?.current?.hide();
      });
  };

  return (
    <FunLayout
      type={EnLayoutType.fixedLayout}
      className={"main-content-p"}
      isAdaptorKeyboard={true}
    >
      <Header hidden={false} className={"top"} history={props.history}>
        长宁劳动仲裁服务平台
      </Header>

      <Skeleton ref={skeletonRef} text={"上传中"}>
        <div className="upload-material-container">
          {caseDetail?.check_upload_reason && (
            <div className="case-check_upload_reason-container">
              <span className="sub-title">驳回原因：</span>
              <span className="case-check_upload_reason">
                {caseDetail?.check_upload_reason}
              </span>
            </div>
          )}

          <span className="main-title">上传证据材料</span>
          <div className="case-name-container">
            <span className="sub-title">案件名：</span>
            <span className="content">
              [{caseDetail?.number}] [{caseDetail?.applyer_name}]诉[
              {caseDetail?.respondent_name}]
            </span>
          </div>
          <div className="case-material">
            <span className="sub-title">证据材料：</span>
            <span className="content">
              为保障上传完成，文件大小超过
              5MB建议在电脑浏览器登录系统上传材料，单个上传文件大小不能超过
              30MB，支持文件格式:pdf、doc、docx、jpg、bmp、png、mp3、mp4、mov、txt、csv、xls、xsx
            </span>
          </div>

          <FileUpload
            className="button-container"
            fileListTitle={"已上传证据材料"}
            fileList={files}
            selectable={true}
            onChange={onUpload}
            onRemove={onRemove}
            accept="
          image/bmp,image/jpg,image/png,
          text/plain,
          application/msword,
          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
          application/pdf,
          audio/mp3,
          video/mp4,
          video/mov,
          .csv,
          application/vnd.ms-excel,
          application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
          "
            FileListItem={FileListItem}
            fileListItemClassName="mine-avatar-upload-img"
            uploadComponent={
              <FunButton className="button-width" type={EnButtonType.white}>
                <div className="button-inner ">
                  <FunIcon icon={"icon-upload"} />
                  <span>上传证据材料</span>
                </div>
              </FunButton>
            }
          />
          <FunButton
            className="button-container"
            type={EnButtonType.primary}
            size={EnButtonSize.big}
            onClick={onFinishUpload}
          >
            完成上传
          </FunButton>
        </div>
      </Skeleton>
    </FunLayout>
  );
}
